export const CONTEXT_PATH = '/QLDS';

export const ACCESS_TOKEN = 'ACCESS_TOKEN';
export const USER_ID = 'USER_ID';
export const USERNAME = 'USERNAME';
export const EMAIL = 'EMAIL';
export const COMPANY_ID = 'COMPANY_ID';
export const COMPANY_NAME = 'COMPANY_NAME';
export const ROLE = 'ROLE';
export const ROLE_NAME = 'ROLE_NAME';
export const STATUS = 'STATUS';

export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
// export const API_BASE_URL = 'http://localhost:8080';
export const API_BASE_URL = 'https://quanlyduongsat.com/api';
export const GET = 'GET';
export const POST = 'POST';
export const PUT = 'PUT';
export const DELETE = 'DELETE';

export const SET_ERROR_MESSAGE = 'SET_ERROR_MESSAGE';
export const SET_SUBMIT_VALUE = 'SET_SUBMIT_VALUE';
export const SET_LOADING_VALUE = 'SET_LOADING_VALUE';
export const SET_MATERIAL_LIST_VALUE = 'SET_MATERIAL_LIST_VALUE';

export const CurrencyFormatter = new Intl.NumberFormat('vn-VN');

export const materialGroups = [
  { id: '1', label: 'Phụ tùng mua mới' },
  { id: '2', label: 'Phụ tùng gia công cơ khí' },
  { id: '3', label: 'Phụ tùng khôi phục' },
];

export const materialTypes = [
  { id: '1521', label: 'Kho nguyên vật liệu chính' },
  { id: '1522', label: 'Kho vật liệu xây dựng cơ bản' },
  { id: '1523', label: 'Kho dầu mỡ bôi trơn' },
  { id: '1524', label: 'Kho phụ tùng' },
  { id: '1525', label: 'Kho nhiên liệu' },
  { id: '1526', label: 'Kho nguyên vật liệu phụ' },
  { id: '1527', label: 'Kho phế liệu' },
  { id: '1528', label: 'Kho phụ tùng gia công cơ khí' },
  { id: '1529', label: 'Kho nhiên liệu tồn trên phương tiện' },
  { id: '1531', label: 'Kho công cụ dụng cụ' },
];

export const engineTypes = [
  { id: 'Đầu máy Bỉ', label: 'Đầu máy Bỉ' },
  { id: 'Đầu máy Ấn Độ', label: 'Đầu máy Ấn Độ' },
  { id: 'Đầu máy Trung Quốc', label: 'Đầu máy Trung Quốc' },
  { id: 'Đầu máy GE', label: 'Đầu máy GE' },
  { id: 'Đầu máy Đức', label: 'Đầu máy Đức' },
  { id: 'Đầu máy Tiệp', label: 'Đầu máy Tiệp' },
  { id: 'Đầu máy Rumani', label: 'Đầu máy Rumani' },
  { id: 'Thiết bị', label: 'Thiết bị' },
  { id: 'Hệ thống điện', label: 'Hệ thống điện' },
  { id: 'Hệ thống nước', label: 'Hệ thống nước' },
];

export const repairLevelList = [
  { id: 'Đột xuất', label: 'Đột xuất' },
  { id: 'Ro', label: 'Ro' },
  { id: 'Rt', label: 'Rt' },
  { id: 'R1', label: 'R1' },
  { id: 'R2', label: 'R2' },
  { id: 'Rk', label: 'Rk' },
  { id: 'Đại tu', label: 'Đại tu' },
  { id: 'Hoán cải - Cải tạo', label: 'Hoán cải - Cải tạo' },
  { id: 'Sự cố - Tai nạn', label: 'Sự cố - Tai nạn' },
];

export const parseLocaleNumber = (stringNumber) => {
  if (stringNumber === undefined || stringNumber == null || stringNumber === '') {
    return null;
  }
  const thousandSeparator = CurrencyFormatter.format(11111).replace(/\p{Number}/gu, '');
  const decimalSeparator = CurrencyFormatter.format(1.1).replace(/\p{Number}/gu, '');
  return parseFloat(stringNumber.replace(new RegExp(`\\${thousandSeparator}`, 'g'), '').replace(new RegExp(`\\${decimalSeparator}`), '.'));
};
